<template>
  <div class="home">
    <div class="banner" @click="goSchoolInfo">
      <img :src="bannerImg" />
      <!-- 学校名称 -->
      <!-- <div class="school-box">
				<div class="welcome">欢迎新同学</div>
				<div class="school-name">{{ userInfo.SchoolName }}</div>
			</div> -->
    </div>
    <div class="my-page">
      <div class="my-card" style="padding:20px 10px 10px 10px">
        <div class="page-type">
          <!--   <div v-if="userType === '3'" class="item" @click="goPage(10)">
            <i class="bbxj"></i>
            <span>宝贝请假</span>
          </div> -->

          <!-- <div v-if="userType === '2'" class="item" @click="goPage(3)">
            <i class="cwj"></i>
            <span>晨午检</span>
          </div>

          <div v-if="userType === '2'" class="item" @click="goPage(2)">
            <i class="yqxd"></i>
            <span>园区消毒</span>
          </div> -->

          <div class="item" @click="goPage(17)" v-if="userType === '2'">
            <van-badge style="height:45px" :content="badge">
              <i class="sx"></i>
            </van-badge>

            <span>审核事项</span>
          </div>

          <!-- 共有的 -->
          <div class="item" @click="goPage(13)">
            <i class="jtjs"></i>
            <span>集团介绍</span>
          </div>

          <div class="item" @click="goPage(14)">
            <i class="news"></i>
            <span>新闻公告</span>
          </div>

          <div class="item" @click="goPage(15)">
            <i class="yqxd"></i>
            <span>卫生保健</span>
          </div>

          <div class="item" @click="goPage(16)">
            <i class="netWorkTeacher" v-if="userType == '2'"></i>
            <i class="netWorkClass" v-else></i>
            <span>云课堂</span>
          </div>

          <!-- 教师端 -->
          <van-popover
            v-if="userType === '2' && isAdministration == 'false'"
            v-model="showPopover"
            placement="top"
          >
            <van-grid
              square
              clickable
              :border="false"
              column-num="3"
              style="width: 250px;"
            >
              <van-grid-item
                :text="leave.text"
                :icon="leave.photo"
                to="/LeaveTeacher"
                @click="showPopover = false"
              />
              <van-grid-item
                :text="overTime.text"
                :icon="overTime.photo"
                to="/OverTime"
                @click="showPopover = false"
              />
              <van-grid-item
                :text="teacherMyAttendance.text"
                :icon="teacherMyAttendance.photo"
                to="/TeacherMyAttendance"
                @click="showPopover = false"
              />
            </van-grid>
            <template #reference>
              <div
                v-if="userType === '2' && isAdministration == 'false'"
                class="popoverItem"
                @click="clickPopover"
              >
                <i class="bbxj"></i>
                <span>教师出勤</span>
              </div>
            </template>
          </van-popover>

          <div
            v-if="userType === '2' && isAdministration == 'false'"
            class="item"
            @click="goPage(19)"
          >
            <i class="cqtj"></i>
            <span>宝贝出勤</span>
          </div>

          <div
            v-if="userType === '2' && isAdministration == 'false'"
            class="item"
            @click="goPage(20)"
          >
            <i class="czc"></i>
            <span>成长册</span>
          </div>

          <!--   <div v-if="userType === '3'" class="item" @click="goPage(10)">
            <i class="bbxj"></i>
            <span>宝贝请假</span>
          </div> -->
          <!--        <div v-if="userType === '3'" class="item" @click="goPage(11)">
            <i class="czc"></i>
            <span>成长册</span>
          </div> -->
        </div>
      </div>
      <!-- 中间按钮功能(只有家长端有) -->
      <middleModule ref="middleModule" v-show="userType == 3"></middleModule>
      <!-- 快讯 -->
      <fastNews ref="fastNews" v-show="isAdministration == 'false'"></fastNews>
      <!-- 通知公告 -->
      <noticeRecord
        ref="noticeRecord"
        v-show="isAdministration == 'false'"
      ></noticeRecord>

      <!-- 朋友圈功能 -->
      <circleShare
        ref="circleShare"
        style="padding-bottom: 10px;"
        v-show="isAdministration == 'false'"
      >
      </circleShare>
    </div>

    <!-- 菜单栏 -->
    <NavBar v-bind:active="0" />
  </div>
</template>
<script>
import NavBar from "@/components/NavBar";
//import bannerImg from "@/assets/img/home-banner2.png";
import bannerImg from "@/assets/home/homeBanner.png";
import groupBannerImg from "@/assets/home/groupBanner.png";
import teacherBannerImg from "@/assets/home/teacherBanner.png";
import parentBannerImg from "@/assets/home/parentBanner.png";
import middleModule from "./component/middleModule.vue";
import fastNews from "./component/fastNews.vue";
import noticeRecord from "./component/noticeRecord.vue";
import circleShare from "./component/circleShare.vue";
import { Empty, Swipe, SwipeItem, Popover, Grid, GridItem, Badge } from "vant";
export default {
  components: {
    NavBar,
    middleModule,
    fastNews,
    noticeRecord,
    circleShare,
    [Empty.name]: Empty,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [Popover.name]: Popover,
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
    [Badge.name]: Badge
  },
  data() {
    return {
      userInfo: JSON.parse(window.localStorage.getItem("userInfo")),
      userType: window.localStorage.getItem("UserType"),
      isAdministration: window.localStorage.getItem("IsAdministration"),
      bannerImg:
        window.localStorage.getItem("IsAdministration") == "true"
          ? groupBannerImg
          : window.localStorage.getItem("UserType") == 2
          ? teacherBannerImg
          : parentBannerImg,
      noticeList: [],
      tabNumer: 0, //顶部按钮index
      showPopover: false,
      leave: {
        text: "请假管理",
        icon: "n",
        photo: require("../../../assets/icon/请假.png")
      },
      overTime: {
        text: "加班管理",
        icon: "n",
        photo: require("../../../assets/icon/加班.png")
      },
      teacherMyAttendance: {
        text: "我的出勤",
        icon: "n",
        photo: require("../../../assets/home/我的出勤.png")
      },
      badge: 0
    };
  },
  mounted() {
    // if (navigator.userAgent.indexOf('iPhone') !== -1) {
    //   window.localStorage.setItem("wechaturl",window.location + '')
    // }
    // 获取通知公告
    //this.getNotice();
    //获取待审核数
    this.getAuditCount();
  },
  methods: {
    getAuditCount() {
      this.$axios.get("/api/Main/GetAuditNum").then(res => {
        if (res.code === 200) {
          this.badge = res.data;
        } else {
          this.badge = 0;
        }
      });
    },
    clickPopover() {
      this.showPopover = !this.showPopover;
    },
    goSchoolInfo() {
      this.$router.push("SchoolInfo");
    },
    goPage(index) {
      const that = this;
      switch (index) {
        case 1:
          that.$router.push("RestViewAll");
          break;
        case 2:
          that.$router.push("DisinfectionList");
          break;
        case 3:
          that.$router.push("MorningCheckList");
          break;
        case 4:
          that.$router.push("RecipesViewAll");
          break;
        case 5:
          that.$router.push("MedicationListParent");
          break;
        case 6:
          that.$router.push("MedicationListTeacher");
          break;
        case 7:
          that.$router.push("RepairManage");
          break;
        case 8:
          that.$router.push("LeaveTeacher");
          break;
        case 9:
          that.$router.push("LeaveBobyAudit");
          break;
        case 10:
          that.$router.push("LeaveBoby");
          break;
        case 11:
          that.$router.push("GrowthBook");
          break;
        case 12:
          that.$router.push("RepairManageZc");
          break;
        case 13:
          that.$router.push("GroupView");
          break;
        case 14:
          that.$router.push("NewsNoticeMain");
          break;
        case 15:
          that.$router.push("RecipesViewAll");
          break;
        case 16:
          that.$router.push("CloudClassroomMain");
          break;
        case 17:
          that.$router.push("AuditSchedule");
          break;
        case 18:
          that.$router.push("LeaveTeacher");
          break;
        case 19:
          that.$router.push("ChildrenAttendance");
          break;
        case 20:
          that.$router.push("growthManualList"); //教师端跳转到学生列表
          break;
        default:
          that.$toast("功能正在开发中...");
          break;
      }
    }
  }
};
</script>
<style lang="less" scoped>
/deep/ .van-icon__image {
  width: 40px;
  height: 45px;
}
/deep/ .van-popover__wrapper {
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
  .popoverItem {
    height: 100%;

    i {
      margin: 0 auto;
      display: flex;
      width: 40px;
      height: 45px;
    }

    .bbxj {
      background: url("../../../assets/home/27.png") center center no-repeat;
      background-size: 100% 100%;
    }
  }
}
@import url("./index.less");
</style>
