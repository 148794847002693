<template>
  <div class="middleModule">
    <div class="my-card">
      <div class="left" @click="goPage(5)">
        <div class="img">
          <img
            src="../../../../assets/home/班级圈.png"
            style="width: 100%; height: 109%; border-top-left-radius: 8px"
          />
        </div>
        <div class="text">
          <p>班级圈</p>
        </div>
      </div>
      <div class="right">
        <div class="item" @click="goPage(1)">
          <div class="image">
            <img src="../../../../assets/home/bbqj.png" class="img" />
          </div>
          <div class="text">宝贝请假</div>
        </div>
        <div class="item" @click="goPage(8)">
          <div class="image">
            <img src="../../../../assets/home/yzsp.png" class="img" />
          </div>
          <div class="text">一周食谱</div>
        </div>
        <div class="item" @click="goPage(2)">
          <div class="image">
            <img src="../../../../assets/home/czc.png" class="img" />
          </div>
          <div class="text">成长册</div>
        </div>
        <!-- <div class="item" @click="goPage(3)">
          <div class="image">
            <img src="../../../../assets/home/bgsc.png" class="img" />
          </div>
          <div class="text">报告上传</div>
        </div> -->
        <div class="item" @click="goPage(6)">
          <div class="image">
            <img src="../../../../assets/home/cqtj.png" class="img" />
          </div>
          <div class="text">宝贝出勤</div>
        </div>
        <div class="item" @click="goPage(4)">
          <div class="image">
            <img src="../../../../assets/home/jzxx.png" class="img" />
          </div>
          <div class="text">家长学校</div>
        </div>
        <div class="item" @click="goPage(7)">
          <div class="image">
            <img src="../../../../assets/home/bbcf.png" class="img" />
          </div>
          <div class="text">宝贝餐费</div>
        </div>
        <!-- <van-popover v-model="showPopover" placement="top">
          <van-grid
            square
            clickable
            :border="false"
            column-num="2"
            style="width: 200px;"
          >
            <van-grid-item
              :text="babyAttance.text"
              :icon="babyAttance.photo"
              to="/parentAttendance"
              @click="showPopover = false"
            />
            <van-grid-item
              :text="leave.text"
              :icon="leave.photo"
              to="/LeaveBoby"
              @click="showPopover = false"
            />
          </van-grid>
          <template #reference>
            <div
              class="popoverItem"
              @click="clickPopover"
            >
              <i class="bbxj"></i>
              <span>宝贝出勤</span>
            </div>
          </template>
        </van-popover> -->
        
      </div>
    </div>
  </div>
</template>

<script>
import {  Popover, Grid, GridItem, Badge } from "vant";
export default {
  components: {
    [Popover.name]: Popover,
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
    [Badge.name]: Badge
  },
  name: "MiddleModule",
  data() {
    return {
      showPopover:false,
      leave: {
        text: "宝贝请假",
        icon: "n",
        photo: require("../../../../assets/home/bbqj.png")
      },
      babyAttance: {
        text: "宝贝出勤",
        icon: "n",
        photo: require("../../../../assets/home/cqtj.png")
      },
    };
  },
  methods: {
    clickPopover() {
      this.showPopover = !this.showPopover;
    },
    goPage(index) {
      const that = this;
      switch (index) {
        case 1:
          that.$router.push("/LeaveBoby");
          break;
        case 2:
          that.$router.push("/growthManualList/childenDetails");
          break;
        case 3:
          that.$router.push("/Folder");
          break;
        case 4:
          that.$router.push("/ParentSchoolMain");
          break;
        case 5:
          that.$router.push("/ClassCircle");
          break;
        case 6:
          that.$router.push("/parentAttendance");
          break;
        case 7:
          that.$router.push("/mealCost");
          break;
        case 8:
          that.$router.push("/RecipesWeek");
          break;
      }
    }
  }
};
</script>

<style lang="less" scoped>
.middleModule {
  .my-card {
    padding: 0px;
    display: flex;

    .left {
      height: 180px;
      width: 40%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .text {
        text-align: center;
        height: 88px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .img {
        height: 66%;
      }
    }

    .right {
      display: flex;
      //justify-content: space-between;
      flex-wrap: wrap;
      align-items: center;
      flex: 1;

      .item {
        width: 33%;
        height: 60px;

        .image {
          display: flex;
          justify-content: center;

          .img {
            width: 26px;
            height: 26px;
          }
        }

        .text {
          text-align: center;
          margin-top: 10px;
        }
      }
    }
  }
}
/deep/ .van-icon__image {
  width: 40px !important;
  height: 40px !important;
}
/deep/ .van-popover__wrapper {
  width: 33% !important;
  height: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
  .popoverItem {
    height: 100%;

    i {
      margin: 0 auto;
      display: flex;
      width: 35px !important;
      height: 42px !important;
    }

    .bbxj {
      background: url("../../../../assets/home/cqtj.png") center center no-repeat;
      background-size: 100% 100%;
    }
  }
}
</style>
