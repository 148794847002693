<template>
	<!-- 快讯 -->
	<div class="fastNews" >
		<div class="my-card" @click="goNoticeView()" v-show="Title">
			<div>
				<img src="../../../../assets/home/12.png" style="width: 36px; height: 18px" />
			</div>
			<div class="right">
				<span>{{ Title }}</span>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "MiddleModule",
		data() {
			return {
				Title: "", //咨询
				InformationType: null,
				ID: "",
			};
		},
		mounted() {
			this._getInfoMations();
		},
		methods: {
			// 咨询信息
			_getInfoMations() {
				this.$axios.get("/api/Main/GetInformation").then((res) => {
					if (res.data) {
						this.Title = res.data.Title;
						this.InformationType = res.data.InformationType;
						this.ID = res.data.ID;
					}
				});
			},
			goNoticeView() {
				if (this.InformationType) {
					if (this.InformationType === 1) {
						this.$router.push({
							path: "NewsNoticeView",
							query: {
								id: this.ID,
							},
						});
					} else if (this.InformationType === 2) {
						this.$router.push({
							path: "KnowledgeView",
							query: {
								id: this.ID,
							},
						});
					} else {
						this.$router.push({
							path: "CloudClassroomView",
							query: {
								id: this.ID,
							},
						});
					}
				}
			},
		},
	};
</script>

<style lang="less" scoped>
	.fastNews {
		width: 100%;

		.my-card {
			display: flex;
			.right {
				display: flex;
				align-items: center;
				padding-right: 5px;
				width: 90%;

				span {
					margin-left: 20px;
					text-overflow: ellipsis;
					white-space: nowrap;
					overflow: hidden;
					width: 100%;
				}
			}
		}
	}
</style>
