<template>
  <!-- 朋友圈 -->
  <div class="circleShare">
    <div class="" style="height: 100px;">
      <top-bar
        ref="top-bar"
        :tabs="['全部', '班级圈', '宝贝圈', '我的宝贝']"
        @tabNum="tabNum"
        v-if="userType == 3"
      ></top-bar>
      <top-bar
        ref="top-bar"
        :tabs="['全部', '班级圈', '宝贝圈']"
        @tabNum="tabNum"
        v-else
        style="width: 50%"
      ></top-bar>
      <!-- 分享日常 -->
      <shareDaily ref="shareDaily" v-show="Principal == 'false'"></shareDaily>
    </div>
    <div class="shareContent" v-if="tabList.length > 0">
      <van-pull-refresh
        v-model="refreshing"
        success-text="刷新成功"
        @refresh="onRefresh"
      >
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="getMore"
        >
          <div
            class="my-card"
            v-for="item in tabList[this.tabNumer]"
            :key="item.ID"
          >
            <!-- 顶部用户信息 -->
            <div class="topUseInfo">
              <div class="left">
                <van-image
                  width="40"
                  height="40"
                  radius="10"
                  :src="item.ProfilePicture"
                />
                <div class="text">
                  <p>
                    {{ item.CreateUser
                    }}<span v-if="item.ClassName">{{
                      "（" + item.ClassName + "）"
                    }}</span>
                  </p>
                  <p>{{ item.CreateDate }}</p>
                </div>
              </div>
              <div
                class="right"
                @click="openDelet(item)"
                v-show="item.IsUseDelete"
              >
                <van-icon name="ellipsis" size="20" />
              </div>
            </div>
            <!-- <div>
							<video muted="true" controls style="width: 200px;height: 200px;border: 1px solid #aaaaaa" loop    class="video"  src="http://15722345670050816.oss-cn-hangzhou.aliyuncs.com/Resource_6b0252f6-ef72-4c98-95af-23db0aaee78e.mp4"  ></video>
						</div> -->
            <!-- 中间内容 -->
            <div class="middleContent">
              <p style="font-size:14px;">{{ item.Content }}</p>
              <div class="imgList">
                <div class="img">
                  <div style="display:flex;flex-wrap:wrap;width:100%">
                    <div
                      v-for="(itm, index) in item.AccessPostList"
                      :key="index"
                      :class="
                        itm.url.substring(itm.url.lastIndexOf('.')) == '.mp4' ||
                        itm.url.substring(itm.url.lastIndexOf('.')) == '.mov'
                          ? 'video_item'
                          : 'img_item'
                      "
                    >
                      <div
                        v-if="
                          itm.url.substring(itm.url.lastIndexOf('.')) ==
                            '.mp4' ||
                            itm.url.substring(itm.url.lastIndexOf('.')) ==
                              '.mov'
                        "
                        style="width: 100%;height: 200px;margin-top:10px"
                      >
                        <video
                          controls
                          playsinline
                          webkit-playsinline
                          x-webkit-airplay="allow"
                          preload="metadata"
                          style="width: 100%;height: 200px;object-fit: cover;"
                          loop
                          class="video"
                          :src="itm.url"
                          :poster="
                            itm.url + '?x-oss-process=video/snapshot,t_1,m_fast'
                          "
                        ></video>
                      </div>
                      <div v-else>
                        <div style="width:100%;height: 100px;margin-top: 10px;">
                          <van-image
                            radius="10"
                            style="width: 100%;height: 100%;"
                            :src="itm.url"
                            @click.stop="
                              () => {
                                images = [];
                                images = item.imgList;
                                imgShow = true;
                              }
                            "
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 底部标签、点赞 -->
            <div class="">
              <div class="">
                <span style="color: #47AFA7">{{
                  "#" + item.SignTypeName
                }}</span>
                <div class="thumbsUp">
                  <div class="left">
                    <!-- <div v-if="item.thumbUpUserList.length == 0">
                      <img
                        src="../../../../assets/home/30.png"
                        width="20"
                        height="20"
                      />
                      <img
                        src="../../../../assets/home/31.png"
                        width="20"
                        height="20"
                      />
                      <img src="../../../../assets/home/32.png" height="20" />
                    </div> -->
                    <div v-if="item.thumbUpUserList.length != 0">
                      <img
                        style="border-radius:10px;"
                        v-for="(thumbupItem,
                        index) in item.thumbUpUserList.slice(0, 3)"
                        :key="index"
                        :src="thumbupItem"
                        width="20"
                        height="20"
                      />
                    </div>
                    <span
                      style="color: #a6a6a6"
                      :style="
                        item.thumbUpUserList.length != 0
                          ? 'margin-left:10px'
                          : ''
                      "
                      >共{{ item.ThumbUpCount }}人点赞</span
                    >
                  </div>
                  <div
                    class="right"
                    @click="thumbsUpClick(item, item.CircleType, item.ID)"
                  >
                    <img
                      src="../../../../assets/home/undz.png"
                      v-if="!item.checked"
                      style="width:16px;height:16px;"
                    />
                    <img
                      src="../../../../assets/home/dz.png"
                      v-else
                      style="width:16px;height:16px;"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </van-list>
      </van-pull-refresh>
    </div>
    <!-- 暂无数据 -->
    <van-empty description="暂无数据" v-else />
    <!-- 图片预览 -->
    <van-image-preview v-model="imgShow" :images="images"> </van-image-preview>
  </div>
</template>

<script>
import topBar from "@/components/common/topBar/index.vue";
import shareDaily from "./shareDaily.vue";

import {
  Col,
  Row,
  PullRefresh,
  Image as VanImage,
  Empty,
  List,
  ImagePreview,
  Icon,
  Dialog,
  Toast
} from "vant";

export default {
  name: "CircleShare",
  components: {
    topBar,
    shareDaily,
    [VanImage.name]: VanImage,
    [Icon.name]: Icon,
    [Col.name]: Col,
    [Row.name]: Row,
    [PullRefresh.name]: PullRefresh,
    [Empty.name]: Empty,
    [List.name]: List,
    [Dialog.name]: Dialog,
    [ImagePreview.Component.name]: ImagePreview.Component,
    [Toast.name]: Toast
  },
  data() {
    return {
      userType: window.localStorage.getItem("UserType"),
      loading: false,
      tabList: [[], [], [], []], //朋友圈列表数据
      tabNumer: 0, //按钮index
      type: [
        {
          CircleType: 0,
          limit: 3,
          page: 0
        },
        {
          CircleType: 1,
          limit: 3,
          page: 0
        },
        {
          CircleType: 2,
          limit: 3,
          page: 0
        },
        {
          CircleType: 3,
          limit: 3,
          page: 0
        }
      ], //圈子类型
      finished: false, //是否更多数据
      refreshing: false, //下拉刷新数据
      images: [],
      imgShow: false,
      Principal: window.localStorage.getItem("Principal")
    };
  },
  mounted() {
    //this._getCircleInfo()
  },
  methods: {
    // 朋友圈信息
    _getCircleInfo() {
      this.$axios
        .get("/Main/GetCircleList", this.type[this.tabNumer])
        .then(res => {
          if (this.refreshing) {
            this.refreshing = false;
          }
          if (res.code == 200) {
            this.loading = false;
            if (res.data) {
              res.data.forEach(item => {
                item.checked = item.IsThumbUp;
              });
              this.tabList[this.tabNumer].push(...res.data);
              this.tabList[this.tabNumer].forEach(item => {
                if (item.AccessPostList && item.AccessPostList.length > 0) {
                  let videoList = [];
                  let imgList = [];
                  item.AccessPostList.forEach(accessItem => {
                    if (
                      accessItem.url.substring(
                        accessItem.url.lastIndexOf(".")
                      ) == ".mp4" ||
                      accessItem.url.substring(
                        accessItem.url.lastIndexOf(".")
                      ) == ".mov"
                    ) {
                      videoList.push(accessItem);
                    } else {
                      imgList.push(accessItem.url);
                    }
                  });
                  item.videoList = videoList;
                  item.imgList = imgList;
                }
              });
              // 数据深层数据无法监听,强刷新实现响应式
              this.$forceUpdate();
              if (this.tabList[this.tabNumer].length >= res.count) {
                this.finished = true;
              }
            } else {
              this.finished = true;
            }
          }
        });
    },
    // 上拉加载更多数据
    getMore() {
      this.type[this.tabNumer].page++;
      this.loading = true;
      this._getCircleInfo();
    },
    //顶部top点击
    tabNum(index) {
      this.tabNumer = index;
      this.type[index].page = 1;
      this.finished = false;
      this.loading = false;
      this.refreshing = false;
      this.tabList[index] = []; //切换按钮,清空原数据
      this._getCircleInfo();
    },
    // 下拉刷新刷新数据状态
    onRefresh() {
      this.finished = false;
      this.type[this.tabNumer].page = 1;
      this.tabList[this.tabNumer] = [];
      //this.loading = true
      this._getCircleInfo();
    },
    // 点赞
    thumbsUpClick(item, CircleType, ID) {
      // 班级圈点赞接口
      this.$axios
        .post(
          CircleType == 1
            ? "/api/ClassCircle/ThumbUp"
            : "/api/ChildrenCircle/ThumbUp",
          CircleType == 1
            ? {
                ClassCircleID: ID
              }
            : {
                ID
              }
        )
        .then(res => {
          // 刷新数据
          item.checked = !item.checked;
          if (item.checked) {
            //点赞
            item.thumbUpUserList.unshift(
              JSON.parse(window.localStorage.getItem("userInfo")).HeadUrl
            );
            item.ThumbUpCount++;
          } else {
            item.thumbUpUserList = item.thumbUpUserList.filter(
              item =>
                item !=
                JSON.parse(window.localStorage.getItem("userInfo")).HeadUrl
            );
            item.ThumbUpCount--;
          }
          this.$forceUpdate();
        });
    },
    // 打开删除对象框
    openDelet(item) {
      Dialog.alert({
        message: "确认删除该条朋友圈?",
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonColor: "#ffe500"
      }).then(() => {
        // 删除接口
        this.$axios
          .post(
            item.CircleType == 1
              ? "/api/ClassCircle/Delete"
              : "/ChildrenCircle/Delete",
            {
              ID: item.ID
            }
          )
          .then(res => {
            if (res.code == 200) {
              this.$toast.success(res.msg);
              this.onRefresh();
              this.$forceUpdate();
            } else {
              that.$toast.fail(res.msg || "操作失败");
            }
          });
      });
    }
  }
};
</script>

<style lang="less" scoped>
.circleShare {
  display: flex;
  flex-direction: column;
  height: 360px;
}

.shareContent {
  //height: calc(100%-60px)!important;
}

.my-card {
  padding-top: 20px;
  .img_item {
    width: 32%;
  }
  .img_item:nth-child(3n + 2) {
    margin: 0 2%;
  }

  .video_item {
    width: 100%;
  }
  .topUseInfo {
    display: flex;
    justify-content: space-between;

    .left {
      display: flex;
      justify-content: flex-start;
    }

    .text {
      margin-left: 10px;

      // p {
      //   text-align: center;
      // }
    }

    p:first-child {
      color: #000000;
      font-size: 15px;
    }

    p:last-child {
      color: #a6a6a6;
      font-size: 10px;
      margin-top: 8px;
    }
  }

  .middleContent {
    margin: 10px 0 10px 0;

    .imgList {
      //margin-top: 10px;
    }
  }

  .thumbsUp {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    //padding-right: 16px;

    .left {
      display: flex;
      align-items: center;
    }
  }
}
</style>
