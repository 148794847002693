<template>
  <!-- 通知公告 -->
  <div class="noticeRecord">
    <div class="notice" v-show="noticeList.length > 0">
      <div class="my-title">
        <div class="title-label">
          <span class="span" style="font-weight: bold">通知公告</span>
          <span class="line"></span>
        </div>
        <div class="title-go" @click="goNoticeAll">查看全部 ></div>
      </div>
      <!-- 通知公告 -->
      <div class="noticeConent">
        <template>
          <div
            :class="noticeList.length > 1 ? 'my-card' : 'my-onlycard'"
            v-for="(item, i) in noticeList"
            :key="item.ID"
            @click="goNoticeView(item)"
          >
            <div class="top">
              <span v-if="i === 0" class="new">最新</span>
              <span class="title">{{ item.Title }}</span>
            </div>
            <div class="bottom" style="margin-top: 10px; font-size: 10px">
              <span class="name">{{ item.CreateUserName + "发布" }}</span>
            </div>
            <div class="bottom_date" style="margin-top: 6px; font-size: 10px">
              <span class="date">发布时间：{{ item.CreateDate }}</span>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NoticeRecord",
  data() {
    return {
      noticeList: [], //通知公告数据
    };
  },
  mounted() {
    // 获取通知公告
    this.getNotice();
  },
  methods: {
    getNotice() {
      this.$axios
        .get("/api/Proclamation/ReceiveList", {
          page: 1,
          limit: 6,
        })
        .then((res) => {
          this.noticeList = res.data;
        });
    },
    //查看全部
    goNoticeAll() {
      this.$router.push({
        path:"NoticeList",
        query:{
          flag:1
        }
      });
    },
    // 详情
    goNoticeView(item) {
      this.$router.push({
        path: "NoticeView",
        query: {
          id: item.ID,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.noticeConent {
  display: flex;
  width: 100%;
  overflow-x: scroll;
  margin-top: 10px;
  .my-card {
    min-width: 60% !important;
    //height: 80px;
  }

  .my-onlycard {
    min-width: 100% !important;
    height: 80px;
    border-radius: 0.21333rem;
    background: #ffffff;
    margin: 0 0 0.26667rem 0;
    padding: 0.4rem;
  }

  .my-card:not(:last-child) {
    margin-right: 10px;
  }

  .top {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    .new {
      background: #ff4444;
      color: #ffffff;
      padding: 2px 6px;
      border-radius: 4px;
      margin-right: 5px;
    }

    .title {
      font-size: 16px;
      color: #222222;
    }
  }
  .bottom {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-top: 10px;

    .name,
    .date {
      color: #a6a6a6;
      margin-right: 10px;
    }
  }
  .bottom_date {
    .date {
      color: #a6a6a6;
      margin-right: 10px;
    }
  }
}
</style>
