var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"middleModule"},[_c('div',{staticClass:"my-card"},[_c('div',{staticClass:"left",on:{"click":function($event){return _vm.goPage(5)}}},[_vm._m(0),_vm._m(1)]),_c('div',{staticClass:"right"},[_c('div',{staticClass:"item",on:{"click":function($event){return _vm.goPage(1)}}},[_vm._m(2),_c('div',{staticClass:"text"},[_vm._v("宝贝请假")])]),_c('div',{staticClass:"item",on:{"click":function($event){return _vm.goPage(8)}}},[_vm._m(3),_c('div',{staticClass:"text"},[_vm._v("一周食谱")])]),_c('div',{staticClass:"item",on:{"click":function($event){return _vm.goPage(2)}}},[_vm._m(4),_c('div',{staticClass:"text"},[_vm._v("成长册")])]),_c('div',{staticClass:"item",on:{"click":function($event){return _vm.goPage(6)}}},[_vm._m(5),_c('div',{staticClass:"text"},[_vm._v("宝贝出勤")])]),_c('div',{staticClass:"item",on:{"click":function($event){return _vm.goPage(4)}}},[_vm._m(6),_c('div',{staticClass:"text"},[_vm._v("家长学校")])]),_c('div',{staticClass:"item",on:{"click":function($event){return _vm.goPage(7)}}},[_vm._m(7),_c('div',{staticClass:"text"},[_vm._v("宝贝餐费")])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img"},[_c('img',{staticStyle:{"width":"100%","height":"109%","border-top-left-radius":"8px"},attrs:{"src":require("../../../../assets/home/班级圈.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text"},[_c('p',[_vm._v("班级圈")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"image"},[_c('img',{staticClass:"img",attrs:{"src":require("../../../../assets/home/bbqj.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"image"},[_c('img',{staticClass:"img",attrs:{"src":require("../../../../assets/home/yzsp.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"image"},[_c('img',{staticClass:"img",attrs:{"src":require("../../../../assets/home/czc.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"image"},[_c('img',{staticClass:"img",attrs:{"src":require("../../../../assets/home/cqtj.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"image"},[_c('img',{staticClass:"img",attrs:{"src":require("../../../../assets/home/jzxx.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"image"},[_c('img',{staticClass:"img",attrs:{"src":require("../../../../assets/home/bbcf.png")}})])
}]

export { render, staticRenderFns }